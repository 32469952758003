<template>
 <div>
  <ServiceSMS
   :acknowledgeTC="this.acknowledgeTC"
   :sales="sales"
   @activate-account="
    this.activateAccount(
     $event,
     'SMS Service Activated',
     'Thank you for activating our sms service.'
    )
   "
  />
 </div>
</template>

<script>
import axios from "axios";
import SMSList from "../components/SMSList.vue";
import SMSHeader from "../components/SMSHeader.vue";
import ServiceSMS from "./ServiceSMS.vue";
import NewSMSForm from "../components/NewSMSForm.vue";
import EditSMSForm from "../components/EditSMSForm.vue";
import NewSMSListForm from "../components/NewSMSListForm.vue";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import SuccessBanner from "../components/SuccessBanner.vue";

import { ChevronDownIcon, PlusIcon } from "@heroicons/vue/solid";

const account = localStorage.getItem("account");
export default {
 name: "SMS",
 components: {
  ComfirmationDialog,
  SMSList,
  SMSHeader,
  ServiceSMS,
  NewSMSForm,
  EditSMSForm,
  NewSMSListForm,
  ChevronDownIcon,
  PlusIcon,
  SuccessBanner,
 },
 data() {
  return {
   account,
   closeForm: false,
   showMessage: false,
   openForm: false,
   openEditForm: false,
   openNewListForm: false,
   smsName: "",
   smsId: "",
   smsAccount: "",
   sales: [],
   smsList: [],
   acknowledgeTC: null,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  openEditFormId(...args) {
   this.openEditForm = true;
   this.openNewListForm = false;
   this.smsName = args[0];
   this.smsId = args[1];
  },
  getSmsAccount() {
   try {
    const options = {
     method: "GET",
     url: `${this.$cookie.getCookie("API")}/api/v1/smsAccount`,
     params: { customerAccount: this.account },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios.request(options).then((res) => {
     if (res.data.response) {
      this.$router.push(`/sms-campaign/campaign`);
     }
     // this.smsAccount = res.data.response;
    });
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getAdminSale() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/admins/sales?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.sales = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
  activateAccount(event, msg, subMsg) {
   if (event) {
    this.acknowledgeTC = true;
    try {
     const options = {
      method: "POST",
      url: `${this.$cookie.getCookie("API")}/api/v1/smsAccount`,
      params: { customerAccount: this.account },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     };

     axios.request(options).then((response) => {
      if (response.data.response === "account activated") {
       this.$router.push(`/sms-recipients/list`);
      }
     });
    } catch (error) {
     this.errorHandling(error);
    }
   } else {
    this.acknowledgeTC = false;
   }
  },
  cancelForm() {
   this.closeForm = !this.closeForm;
   this.$forceUpdate();
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
 mounted() {
  // this.getSmsAccount();
  this.getAdminSale();
 },
};
</script>

<style></style>
